<template>
  <div>
    <p
      class="fs-20 fw-500 border-b border-solid border-t-0 border-r-0 border-l-0 border-gray-200"
    >
      <span
        class="txt-grey-900 inline-block border-b-4 border-solid border-t-0 border-r-0 border-l-0 border-pri-900"
        >{{ $t("order_confirm.lbl_billing") }}</span
      >
    </p>
    <!-- <div class="flex justify-content-between">
      <p class="fs-16">Tổng tiền hàng</p>
      <p class="fs-18">{{ amount ? appUtils.numberFormat(amount) : 0 }} VNĐ</p>
    </div>-->
    <!-- <div class="flex justify-content-between">
      <p class="fs-16">Phí vận chuyển</p>
      <p class="fs-18">0 VNĐ</p>
    </div>
    <div class="flex justify-content-between">
      <p class="fs-16">Ưu đãi</p>
      <p class="fs-18">0 VNĐ</p>
    </div>-->
    <div class="flex justify-content-between">
      <p class="fs-16">{{ $t("order_confirm.lbl_total_product_cost") }}</p>
      <p class="fs-18 fw-500 txt-pri">
        {{ totalDefaultPrice ? appUtils.numberFormat(totalDefaultPrice) : 0 }}
        VNĐ
      </p>
    </div>
    <div v-if="otherExpensesAmount" class="flex justify-content-between">
      <p class="fs-16">{{ $t("order_confirm.lbl_other_cost") }}</p>
      <p class="fs-18 fw-500 txt-pri">
        {{
          otherExpensesAmount ? appUtils.numberFormat(otherExpensesAmount) : 0
        }}
        VNĐ
      </p>
    </div>
    <template v-if="!!discountMoney">
      <div class="flex justify-content-between">
        <p class="fs-16">{{ $t("order_confirm.lbl_voucher") }}</p>
        <p class="fs-18 fw-500 txt-pri">
          - {{ discountMoney ? appUtils.numberFormat(discountMoney) : 0 }} VNĐ
        </p>
      </div>
      <hr />

      <div class="flex justify-content-between">
        <p class="fs-16" style="font-weight: bold">
          {{ $t("order_confirm.lbl_billing") }}
        </p>
        <p class="fs-18 fw-500 txt-pri">
          {{ remainingMoney ? appUtils.numberFormat(remainingMoney) : 0 }} VNĐ
        </p>
      </div>
    </template>
    <slot />
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
export default {
  name: 'OrderAmount',
  props: {
    cart: Array,
    discountPercent: {
      type: Number,
      default: 0
    },
    listProductVoucherAppliableIds: {
      type: Array,
      default: () => []
    },
    voucherData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return { appUtils }
  },
  computed: {
    totalDefaultPrice () {
      const data = this.cart || this.$shoppingCart
      return data.reduce((accum, item) => {
        return accum + item.selected_variant?.price * item.count
      }, 0)
    },
    amount () {
      const data = this.cart || this.$shoppingCart
      return data.reduce((accum, item) => {
        let totalRaw = 0
        // eslint-disable-next-line no-unused-expressions
        item?.other_expenses?.map((el) => {
          totalRaw += el?.value
        })
        return (
          accum + item.selected_variant?.price * item.count + (totalRaw || 0)
        )
      }, 0)
    },
    otherExpensesAmount () {
      const data = this.cart || this.$shoppingCart
      return data.reduce((accum, item) => {
        let totalRaw = 0
        // eslint-disable-next-line no-unused-expressions
        item?.other_expenses?.map((el) => {
          totalRaw += el?.value
        })
        return accum + (totalRaw || 0)
      }, 0)
    },
    discountMoney () {
      const data = this.cart || this.$shoppingCart
      const listProductApplyingVoucher = data.filter((item) =>
        this.listProductVoucherAppliableIds.includes(item.id)
      )
      let discountAmount = 0
      listProductApplyingVoucher.forEach((item) => {
        if (this.voucherData?.type === 1) {
          discountAmount += this.voucherData?.discount_amount || 0
        } else if (this.voucherData?.type === 2) {
          discountAmount +=
            (item.selected_variant?.price * this.discountPercent) / 100
        }
      })

      return discountAmount
    },
    remainingMoney () {
      const remaining = this.amount - this.discountMoney
      return remaining > 0 ? remaining : 0
    }
  }
}
</script>

<style scoped>
</style>
